export const fantomPools = [
  {
    id: 'fantom-bifi-maxi',
    logo: 'single-assets/BIFI.png',
    name: 'BIFI Maxi',
    token: 'BIFI',
    tokenDescription: 'Beefy.Finance',
    tokenAddress: '0xd6070ae98b8069de6B494332d1A1a81B6179D960',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFantomBIFI',
    earnedTokenAddress: '0xbF07093ccd6adFC3dEB259C557b61E94c1F66945',
    earnContractAddress: '0xbF07093ccd6adFC3dEB259C557b61E94c1F66945',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BIFI',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beefy.Finance',
    assets: ['BIFI'],
    withdrawalFee: '0.05%',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'curve-ftm-tricrypto',
    logo: 'uncategorized/ATRICRYPTO.png',
    name: 'TriCrypto',
    token: 'crv3crypto',
    tokenDescription: 'Curve',
    tokenAddress: '0x58e57cA18B7A47112b877E31929798Cd3D703b0f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveTriCrypto',
    earnedTokenAddress: '0xeeE5EA8949F622090CFc16d141305d5120DF8dA4',
    earnContractAddress: '0xeeE5EA8949F622090CFc16d141305d5120DF8dA4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-tricrypto',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['fUSDT', 'BTC', 'ETH'],
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://ftm.curve.fi/tricrypto/deposit',
  },
  {
    id: 'spirit-ftm-usdc',
    name: 'USDC-FTM LP',
    token: 'USDC-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xe7E90f5a767406efF87Fdad7EB07ef407922EC1D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritUSDC-WFTM',
    earnedTokenAddress: '0xA4e2EE5a7fF51224c27C98098D8DB5C770bAAdbE',
    earnContractAddress: '0xA4e2EE5a7fF51224c27C98098D8DB5C770bAAdbE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['USDC', 'FTM'],
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    buyTokenUrl: 'https://swap.spiritswap.finance/#/swap',
  },
  {
    id: 'spirit-fusdt-ftm',
    name: 'fUSDT-FTM LP',
    token: 'fUSDT-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xd14Dd3c56D9bc306322d4cEa0E1C49e9dDf045D4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritfUSDT-WFTM',
    earnedTokenAddress: '0xD8dd2EA228968F7f043474Db610A20aF887866c7',
    earnContractAddress: '0xD8dd2EA228968F7f043474Db610A20aF887866c7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-fusdt-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['fUSDT', 'FTM'],
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x049d68029688eAbF473097a2fC38ef61633A3C7A/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    buyTokenUrl: 'https://swap.spiritswap.finance/#/swap',
  },
  {
    id: 'spirit-ftm-mim',
    name: 'MIM-FTM LP',
    token: 'MIM-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xB32b31DfAfbD53E310390F641C7119b5B9Ea0488',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritWFTM-MIM',
    earnedTokenAddress: '0x10940BD183E39A21e9E19bF2d6551a84191B1582',
    earnContractAddress: '0x10940BD183E39A21e9E19bF2d6551a84191B1582',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-mim',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['MIM', 'FTM'],
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x82f0b8b456c1a451378467398982d4834b6829c1',
    buyTokenUrl: 'https://swap.spiritswap.finance/#/swap',
  },
  {
    id: 'spirit-crv-ftm',
    name: 'CRV-FTM LP',
    token: 'CRV-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x374C8ACb146407Ef0AE8F82BaAFcF8f4EC1708CF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritCRV-WFTM',
    earnedTokenAddress: '0xAbab1D681bCF375d1bE78e9F57207F6dd015B4BC',
    earnContractAddress: '0xAbab1D681bCF375d1bE78e9F57207F6dd015B4BC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-crv-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['CRV', 'FTM'],
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x1E4F97b9f9F913c46F1632781732927B9019C68b/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/swap/0x1E4F97b9f9F913c46F1632781732927B9019C68b',
  },
  {
    id: 'spirit-zoo-ftm',
    name: 'ZOO-FTM LP',
    token: 'ZOO-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xDF18DD2631f02D930071DF7d6FF89bbc3718C62F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritZOO-WFTM',
    earnedTokenAddress: '0x9F0d2c24BB2F4b6024F9A266Aa95536481D78ABe',
    earnContractAddress: '0x9F0d2c24BB2F4b6024F9A266Aa95536481D78ABe',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-zoo-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['ZOO', 'FTM'],
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x09e145A1D53c0045F41aEEf25D8ff982ae74dD56/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/swap/0x09e145A1D53c0045F41aEEf25D8ff982ae74dD56',
  },
  {
    id: 'spirit-ftm-ice',
    logo: 'fantom/ICE-FTM.png',
    name: 'ICE-FTM LP',
    token: 'ICE-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x936D23C83c2469f6a14B9f5bEaec13879598A5aC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritWFTM-ICE',
    earnedTokenAddress: '0x7579F5105f73ddC5bb1e969d3CE275f1Ea3e5E08',
    earnContractAddress: '0x7579F5105f73ddC5bb1e969d3CE275f1Ea3e5E08',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-ice',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['ICE', 'FTM'],
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0xf16e81dce15B08F326220742020379B855B87DF9/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/swap/0xf16e81dce15B08F326220742020379B855B87DF9',
  },
  {
    id: 'jetswap-fantom-eth-btc',
    name: 'ETH-BTC LP',
    token: 'ETH-BTC LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x04C25d06eD3984AAE14CA705d3CaF97Fb7238799',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapETH-BTC',
    earnedTokenAddress: '0x3f812916E5C050305D8b4744f0254DE3e195d5E5',
    earnContractAddress: '0x3f812916E5C050305D8b4744f0254DE3e195d5E5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-fantom-eth-btc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetSwap',
    assets: ['ETH', 'BTC'],
    addLiquidityUrl:
      'https://fantom-exchange.jetswap.finance/#/add/0x74b23882a30290451A17c44f4F05243b6b58C76d/0x321162Cd933E2Be498Cd2267a90534A804051b11',
  },
  {
    id: 'jetswap-fantom-eth-usdc',
    name: 'ETH-USDC LP',
    token: 'ETH-USDC LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0xD21A2c9032D09B7174dB93736b834D2a7eaDab15',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapETH-USDC',
    earnedTokenAddress: '0x34D94202E8B006A7FAA46ebED7844B3A738f4E09',
    earnContractAddress: '0x34D94202E8B006A7FAA46ebED7844B3A738f4E09',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-fantom-eth-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetSwap',
    assets: ['ETH', 'USDC'],
    addLiquidityUrl:
      'https://fantom-exchange.jetswap.finance/#/add/0x04068da6c83afcfa0e13ba15a6696662335d5b75/0x74b23882a30290451A17c44f4F05243b6b58C76d',
  },
  {
    id: 'jetswap-fantom-btc-usdc',
    name: 'BTC-USDC LP',
    token: 'BTC-USDC LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x6B5340dFcd7D509Ea931cC4E69462797DbBc0197',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapBTC-USDC',
    earnedTokenAddress: '0x8A5e335F6df7d73B7a6c33E0DA3FEE7a36cc0Da4',
    earnContractAddress: '0x8A5e335F6df7d73B7a6c33E0DA3FEE7a36cc0Da4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-fantom-btc-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetSwap',
    assets: ['BTC', 'USDC'],
    addLiquidityUrl:
      'https://fantom-exchange.jetswap.finance/#/add/0x049d68029688eabf473097a2fc38ef61633a3c7a/0x321162Cd933E2Be498Cd2267a90534A804051b11',
  },
  {
    id: 'jetswap-fantom-fusdt-usdc',
    name: 'fUSDT-USDC LP',
    token: 'fUSDT-USDC LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0xB8D08e75440b89c2c25a400Ab1883B689C0A1A64',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapFUSDT-USDC',
    earnedTokenAddress: '0xf927c93C41DdF0590963fed124f5E148bED654A8',
    earnContractAddress: '0xf927c93C41DdF0590963fed124f5E148bED654A8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-fantom-fusdt-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetSwap',
    assets: ['fUSDT', 'USDC'],
    addLiquidityUrl:
      'https://fantom-exchange.jetswap.finance/#/add/0x04068da6c83afcfa0e13ba15a6696662335d5b75/0x049d68029688eabf473097a2fc38ef61633a3c7a',
  },
  {
    id: 'jetswap-fantom-fwings-ftm',
    name: 'fWINGS-FTM LP',
    token: 'fWINGS-FTM LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x1047f356D7cB29D5939724c69E4eE61e820aFAC6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapWINGS-FTM',
    earnedTokenAddress: '0xAe3F0C61F3Dc48767ccCeF3aD50b29437BE4b1a4',
    earnContractAddress: '0xAe3F0C61F3Dc48767ccCeF3aD50b29437BE4b1a4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-fantom-fwings-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetSwap',
    assets: ['fWINGS', 'FTM'],
    addLiquidityUrl:
      'https://fantom-exchange.jetswap.finance/#/add/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x3D8f1ACCEe8e263F837138829B6C4517473d0688',
    buyTokenUrl:
      'https://fantom-exchange.jetswap.finance/#/swap?outputCurrency=0x3D8f1ACCEe8e263F837138829B6C4517473d0688',
  },
  {
    id: 'jetswap-fantom-fwings-usdc',
    name: 'fWINGS-USDC LP',
    token: 'fWINGS-USDC LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x89fF795017AE21A8696d371F685Cd02FC219F56F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapWINGS-USDC',
    earnedTokenAddress: '0x9E75f8298e458B76382870982788988A0799195b',
    earnContractAddress: '0x9E75f8298e458B76382870982788988A0799195b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-fantom-fwings-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetSwap',
    assets: ['fWINGS', 'USDC'],
    addLiquidityUrl:
      'https://fantom-exchange.jetswap.finance/#/add/0x04068da6c83afcfa0e13ba15a6696662335d5b75/0x3D8f1ACCEe8e263F837138829B6C4517473d0688',
    buyTokenUrl:
      'https://fantom-exchange.jetswap.finance/#/swap?outputCurrency=0x3D8f1ACCEe8e263F837138829B6C4517473d0688',
  },
  {
    id: 'jetswap-fantom-fwings',
    logo: 'single-assets/fWINGS.svg',
    name: 'fWINGS',
    token: 'fWINGS',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x3D8f1ACCEe8e263F837138829B6C4517473d0688',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapWINGS',
    earnedTokenAddress: '0x15c0a37ee6fDA217f0f10dC88df02e7A52e377CA',
    earnContractAddress: '0x15c0a37ee6fDA217f0f10dC88df02e7A52e377CA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'fWINGS',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetSwap',
    assets: ['fWINGS'],
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://fantom-exchange.jetswap.finance/#/swap?outputCurrency=0x3D8f1ACCEe8e263F837138829B6C4517473d0688',
  },
  {
    id: 'tomb-tshare-ftm',
    logo: 'fantom/TSHARE-FTM.png',
    name: 'TSHARE-FTM LP',
    token: 'TSHARE-FTM LP',
    tokenDescription: 'SpookySwap (Tomb)',
    tokenAddress: '0x4733bc45eF91cF7CcEcaeeDb794727075fB209F2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombTSHARE-FTM',
    earnedTokenAddress: '0xae94e96bF81b3a43027918b138B71a771D381150',
    earnContractAddress: '0xae94e96bF81b3a43027918b138B71a771D381150',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-tshare-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'TombFinance',
    assets: ['TSHARE', 'FTM'],
    addLiquidityUrl:
      'https://spookyswap.finance/add/FTM/0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
  },
  {
    id: 'tomb-tomb-ftm',
    logo: 'fantom/TOMB-FTM.png',
    name: 'TOMB-FTM LP',
    token: 'TOMB-FTM LP',
    tokenDescription: 'SpookySwap (Tomb)',
    tokenAddress: '0x2A651563C9d3Af67aE0388a5c8F89b867038089e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombTOMB-FTM',
    earnedTokenAddress: '0x27c77411074ba90cA35e6f92A79dAd577c05A746',
    earnContractAddress: '0x27c77411074ba90cA35e6f92A79dAd577c05A746',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-tomb-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'TombFinance',
    assets: ['TOMB', 'FTM'],
    addLiquidityUrl:
      'https://spookyswap.finance/add/FTM/0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
  },
  {
    id: 'boo-usdc-woo',
    name: 'WOO-USDC LP',
    token: 'WOO-USDC LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x7d4CdDAB6774Ff47D62253067958dbD749Cb7F06',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooWOO-USDC',
    earnedTokenAddress: '0x45A75B80D92a172F8b940AC1da4568BD2ee4A4C7',
    earnContractAddress: '0x45A75B80D92a172F8b940AC1da4568BD2ee4A4C7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-usdc-woo',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['WOO', 'USDC'],
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spookyswap.finance/add/0x6626c47c00F1D87902fc13EECfaC3ed06D5E8D8a/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-wftm-shade',
    name: 'SHADE-FTM LP',
    token: 'SHADE-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x20aa395F3bcc4dc44a94215D129650533B3da0b3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooSHADE-FTM',
    earnedTokenAddress: '0xc178cDdDc277D5aFac90EEc3F833EF3a11Fbc9df',
    earnContractAddress: '0xc178cDdDc277D5aFac90EEc3F833EF3a11Fbc9df',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-shade',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['SHADE', 'FTM'],
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spookyswap.finance/add/0x3A3841f5fa9f2c283EA567d5Aeea3Af022dD2262/FTM',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-wftm-treeb',
    name: 'TREEB-FTM LP',
    token: 'TREEB-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xe8b72a866b8D59F5c13D2ADEF96E40A3EF5b3152',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooTREEB-FTM',
    earnedTokenAddress: '0x6C4593D4f91c790404cCEe5007E929d2b7bF45bD',
    earnContractAddress: '0x6C4593D4f91c790404cCEe5007E929d2b7bF45bD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-treeb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['TREEB', 'FTM'],
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spookyswap.finance/add/0xc60D7067dfBc6f2caf30523a064f416A5Af52963/FTM',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'scream-crv',
    logo: 'single-assets/CRV.png',
    name: 'CRV',
    token: 'CRV',
    tokenDescription: 'Scream',
    tokenAddress: '0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamCRV',
    earnedTokenAddress: '0xE8883Cd90A2D6F2877E130120965D61918d7CE44',
    earnContractAddress: '0xE8883Cd90A2D6F2877E130120965D61918d7CE44',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'CRV',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['CRV'],
    withdrawalFee: '0%',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'scream-link',
    logo: 'single-assets/LINK.png',
    name: 'LINK',
    token: 'LINK',
    tokenDescription: 'Scream',
    tokenAddress: '0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamLINK',
    earnedTokenAddress: '0x6DfE2AAEA9dAadADf0865B661b53040E842640f8',
    earnContractAddress: '0x6DfE2AAEA9dAadADf0865B661b53040E842640f8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'LINK',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['LINK'],
    withdrawalFee: '0%',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-boo',
    logo: 'fantom/BOO.png',
    name: 'BOO',
    token: 'BOO',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooBOO',
    earnedTokenAddress: '0x15DD4398721733D8273FD4Ed9ac5eadC6c018866',
    earnContractAddress: '0x15DD4398721733D8273FD4Ed9ac5eadC6c018866',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BOO',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['BOO'],
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'stakesteak-fusd-usdc',
    name: 'fUSD-USDC sAMM LP',
    token: 'fUSD-USDC sAMM LP',
    tokenDescription: 'StakeSteak',
    tokenAddress: '0xa0828eE559110b041DEdbf10Ae0cf42274251de1',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStakesteakFUSD-USDC',
    earnedTokenAddress: '0x94A654bA3b4052a94EB795E9ff0e9209B0A54dB3',
    earnContractAddress: '0x94A654bA3b4052a94EB795E9ff0e9209B0A54dB3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stakesteak-fusd-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'StakeSteak',
    assets: ['fUSD', 'USDC'],
    depositFee: '0.5%',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://stakesteak.app/',
    buyTokenUrl:
      'https://spookyswap.finance/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0xAd84341756Bf337f5a0164515b1f6F993D194E1f',
  },
  {
    id: 'boo-steak-wftm',
    name: 'STEAK-FTM LP',
    token: 'STEAK-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xD10CC1b46D806D6ac803AF367Da489dEA5EE76FA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooSTEAK-WFTM',
    earnedTokenAddress: '0x3497a17b4680Ef47Ff5B41B00DD4068CAa356A91',
    earnContractAddress: '0x3497a17b4680Ef47Ff5B41B00DD4068CAa356A91',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-steak-wftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['STEAK', 'FTM'],
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spookyswap.finance/add/0x05848B832E872d9eDd84AC5718D58f21fD9c9649/FTM',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-wftm-foo',
    name: 'FTM-FOO LP',
    token: 'FTM-FOO LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x157f913Ed0961a8D0d06a258019840919111D9B9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooWFTM-FOO',
    earnedTokenAddress: '0x4F4e8c1055d0b58F17593A0086608fc6949e5684',
    earnContractAddress: '0x4F4e8c1055d0b58F17593A0086608fc6949e5684',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-foo',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['FOO', 'FTM'],
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spookyswap.finance/add/FTM/0xFbc3c04845162F067A0B6F8934383E63899c3524',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  // wait pool contract verification
  // {
  //   id: 'tosdisftm-dis-wftm',
  //   name: 'DIS-FTM LP',
  //   token: 'DIS-FTM LP',
  //   tokenDescription: 'TosDis (SpiritSwap)',
  //   tokenAddress: '0xa6020794594568e2bf987718b6520C14b9C4Ccd9',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooTosDisDIS-FTM',
  //   earnedTokenAddress: '0xD382E78993E4545353CCaFbae84ba140d42f4739',
  //   earnContractAddress: '0xD382E78993E4545353CCaFbae84ba140d42f4739',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'tosdisftm-dis-wftm',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'DIS',
  //   assets: ['DIS', 'FTM'],
  //   addLiquidityUrl:
  //     'https://swap.spiritswap.finance/#/add/FTM/0x0e121961DD741C9D49C9A04379da944A9D2FAc7a',
  //   buyTokenUrl:
  //     'https://swap.spiritswap.finance/#/swap/0x0e121961DD741C9D49C9A04379da944A9D2FAc7a',
  // },
  {
    id: 'boo-ftm-scream',
    name: 'SCREAM-FTM LP',
    token: 'SCREAM-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x30872e4fc4edbFD7a352bFC2463eb4fAe9C09086',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-SCREAM',
    earnedTokenAddress: '0xcA68685C99dB20ffFa999Af52178077d6f0A3E73',
    earnContractAddress: '0xcA68685C99dB20ffFa999Af52178077d6f0A3E73',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-ftm-scream',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['SCREAM', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-zoo-ftm',
    name: 'ZOO-FTM LP',
    token: 'ZOO-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x3Cceb477Fcb6cDE90180983642486E68148D7b27',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooZOO-FTM',
    earnedTokenAddress: '0xe87d98ce222435455706529c8Ff8E12973792acb',
    earnContractAddress: '0xe87d98ce222435455706529c8Ff8E12973792acb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-zoo-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['ZOO', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'steakhouse-scream-ftm',
    name: 'SCREAM-FTM LP',
    token: 'SCREAM-FTM LP',
    tokenDescription: 'SteakHouse',
    tokenAddress: '0x30872e4fc4edbFD7a352bFC2463eb4fAe9C09086',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSteakHouseSCREAM-FTM',
    earnedTokenAddress: '0xF782E675B93d22FD810Dca4149D29Ab32e5B2972',
    earnContractAddress: '0xF782E675B93d22FD810Dca4149D29Ab32e5B2972',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'steakhouse-scream-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    assets: ['SCREAM', 'FTM'],
    depositFee: '0.5%',
    withdrawalFee: '0%',
    platform: 'Other',
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'scream-ftm',
    logo: 'single-assets/FTM.png',
    name: 'FTM',
    token: 'FTM',
    tokenDescription: 'Scream',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamFTM',
    earnedTokenAddress: '0x49c68eDb7aeBd968F197121453e41b8704AcdE0C',
    earnContractAddress: '0x49c68eDb7aeBd968F197121453e41b8704AcdE0C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WFTM',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['FTM'],
    withdrawalFee: '0%',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'scream-usdc',
    logo: 'single-assets/USDC.svg',
    name: 'USDC',
    token: 'USDC',
    tokenDescription: 'Scream',
    tokenAddress: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamUSDC',
    earnedTokenAddress: '0x2438009ba14A93e82ab43c66838e57bE27A55Aa1',
    earnContractAddress: '0x2438009ba14A93e82ab43c66838e57bE27A55Aa1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['USDC'],
    withdrawalFee: '0%',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'scream-wbtc',
    logo: 'single-assets/WBTC.svg',
    name: 'WBTC',
    token: 'WBTC',
    tokenDescription: 'Scream',
    tokenAddress: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    tokenDecimals: 8,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamWBTC',
    earnedTokenAddress: '0x97927aBfE1aBBE5429cBe79260B290222fC9fbba',
    earnContractAddress: '0x97927aBfE1aBBE5429cBe79260B290222fC9fbba',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBTC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['BTC'],
    withdrawalFee: '0.01%',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'scream-dai',
    logo: 'single-assets/DAI.svg',
    name: 'DAI',
    token: 'DAI',
    tokenDescription: 'Scream',
    tokenAddress: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamDAI',
    earnedTokenAddress: '0x920786cff2A6f601975874Bb24C63f0115Df7dc8',
    earnContractAddress: '0x920786cff2A6f601975874Bb24C63f0115Df7dc8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DAI',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['DAI'],
    withdrawalFee: '0%',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'scream-eth',
    logo: 'single-assets/ETH.svg',
    name: 'wETH',
    token: 'wETH',
    tokenDescription: 'Scream',
    tokenAddress: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamETH',
    earnedTokenAddress: '0x0a03D2C1cFcA48075992d810cc69Bd9FE026384a',
    earnContractAddress: '0x0a03D2C1cFcA48075992d810cc69Bd9FE026384a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ETH',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['ETH'],
    withdrawalFee: '0%',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'scream-fusdt',
    logo: 'single-assets/USDT.svg',
    name: 'fUSDT',
    token: 'fUSDT',
    tokenDescription: 'Scream',
    tokenAddress: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamfUSDT',
    earnedTokenAddress: '0xb09cf345294aDD1066543B22FD7384185F7C6fCA',
    earnContractAddress: '0xb09cf345294aDD1066543B22FD7384185F7C6fCA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDT',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['fUSDT'],
    withdrawalFee: '0%',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-yfi-eth',
    name: 'YFI-ETH LP',
    token: 'YFI-ETH LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x0845c0bFe75691B1e21b24351aAc581a7FB6b7Df',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooYFI-ETH',
    earnedTokenAddress: '0x93EA5929f636f71F343122Ce396340f8E0BC3A70',
    earnContractAddress: '0x93EA5929f636f71F343122Ce396340f8E0BC3A70',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-yfi-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['YFI', 'ETH'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'curve-ftm-2pool',
    logo: 'uncategorized/crv2pool.png',
    name: 'DAI/USDC',
    token: '2poolCRV',
    tokenDescription: 'Curve',
    tokenAddress: '0x27E611FD27b276ACbd5Ffd632E5eAEBEC9761E40',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurve2Pool',
    earnedTokenAddress: '0x7715d9458683288024B9e20D1319DC162361B06c',
    earnContractAddress: '0x7715d9458683288024B9e20D1319DC162361B06c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-2pool',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['USDC', 'DAI'],
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://ftm.curve.fi/2pool/deposit',
  },
  {
    id: 'curve-ftm-fusdt',
    logo: 'single-assets/USDT.svg',
    name: 'fUSDT/DAI/USDC',
    token: 'fusdtCRV',
    tokenDescription: 'Curve',
    tokenAddress: '0x92D5ebF3593a92888C25C0AbEF126583d4b5312E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveFUSDT',
    earnedTokenAddress: '0xBdA8bC79705BC60226adCA2766e94Eb5512949a3',
    earnContractAddress: '0xBdA8bC79705BC60226adCA2766e94Eb5512949a3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-fusdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['fUSDT', 'USDC', 'DAI'],
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://ftm.curve.fi/fusdt/deposit',
  },
  {
    id: 'curve-ftm-ren',
    logo: 'single-assets/renBTC.png',
    name: 'BTC/renBTC',
    token: 'btcCRV',
    tokenDescription: 'Curve',
    tokenAddress: '0x5B5CFE992AdAC0C9D48E05854B2d91C73a003858',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveRenBTC',
    earnedTokenAddress: '0xda3c57A81aCe16b2cC34e8872e886575f8ccf672',
    earnContractAddress: '0xda3c57A81aCe16b2cC34e8872e886575f8ccf672',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-ren',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['BTC', 'renBTC'],
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://ftm.curve.fi/ren/deposit',
  },
  {
    id: 'boo-mim-ftm',
    name: 'MIM-FTM LP',
    token: 'MIM-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x6f86e65b255c9111109d2D2325ca2dFc82456efc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooMIM-FTM',
    earnedTokenAddress: '0x6676C93eb0F2daEfD3b9d03De2E3b18F888cF942',
    earnContractAddress: '0x6676C93eb0F2daEfD3b9d03De2E3b18F888cF942',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-mim-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['FTM', 'MIM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-boo-ftm',
    name: 'BOO-FTM LP',
    token: 'BOO-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xEc7178F4C41f346b2721907F5cF7628E388A7a58',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooBoo-FTM',
    earnedTokenAddress: '0xEe3a7c885Fd3cc5358FF583F2DAB3b8bC473316f',
    earnContractAddress: '0xEe3a7c885Fd3cc5358FF583F2DAB3b8bC473316f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-boo-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['FTM', 'BOO'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-bifi-ftm',
    logo: 'fantom/BIFI-FTM.png',
    name: 'BIFI-FTM LP',
    token: 'BIFI-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x1656728af3a14e1319F030Dc147fAbf6f627059e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooBIFI-FTM',
    earnedTokenAddress: '0xe8188B9701E6DB1Fe24c75783474D22e5957BBEF',
    earnContractAddress: '0xe8188B9701E6DB1Fe24c75783474D22e5957BBEF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-bifi-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['BIFI', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-ftm-ice',
    logo: 'fantom/ICE-FTM.png',
    name: 'ICE-FTM LP',
    token: 'ICE-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x623EE4a7F290d11C11315994dB70FB148b13021d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-ICE',
    earnedTokenAddress: '0x33E0Feb374C9B7fC3Ac392140BdE3036263D8e5e',
    earnContractAddress: '0x33E0Feb374C9B7fC3Ac392140BdE3036263D8e5e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-ftm-ice',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['FTM', 'ICE'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-ftm-badger-eol',
    logo: 'fantom/BADGER-FTM.png',
    name: 'BADGER-FTM LP',
    token: 'BADGER-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x1c94665FD3ecFa969Feda7Ed01e35522e6982022',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-BADGER',
    earnedTokenAddress: '0x9344e006518af9739F4077014F8B7794777Ae96F',
    earnContractAddress: '0x9344e006518af9739F4077014F8B7794777Ae96F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-ftm-badger',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SpookySwap',
    assets: ['FTM', 'BADGER'],
    retireReason: 'rewards',
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-ftm-band-eol',
    logo: 'fantom/BAND-FTM.png',
    name: 'BAND-FTM LP',
    token: 'BAND-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x91b39D5584e2A7DC829f696235742cc293F2e8cf',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-BAND',
    earnedTokenAddress: '0xf2e4C5b0Ed40716cB23cCE7eBd15ECDCDa934EDf',
    earnContractAddress: '0xf2e4C5b0Ed40716cB23cCE7eBd15ECDCDa934EDf',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-ftm-band',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SpookySwap',
    assets: ['FTM', 'BAND'],
    retireReason: 'rewards',
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-ftm-sushi',
    logo: 'fantom/SUSHI-FTM.png',
    name: 'SUSHI-FTM LP',
    token: 'SUSHI-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xf84E313B36E86315af7a06ff26C8b20e9EB443C3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-SUSHI',
    earnedTokenAddress: '0xb8c90D7C60Cf88915BCc4F388F207D5F7fDaA9b4',
    earnContractAddress: '0xb8c90D7C60Cf88915BCc4F388F207D5F7fDaA9b4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-ftm-sushi',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['FTM', 'SUSHI'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-ftm-usdc',
    logo: 'fantom/USDC-FTM.png',
    name: 'FTM-USDC LP',
    token: 'FTM-USDC LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-USDC',
    earnedTokenAddress: '0x41D44B276904561Ac51855159516FD4cB2c90968',
    earnContractAddress: '0x41D44B276904561Ac51855159516FD4cB2c90968',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-ftm-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['FTM', 'USDC'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-any-ftm',
    logo: 'fantom/ANY-FTM.png',
    name: 'ANY-FTM LP',
    token: 'ANY-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x5c021D9cfaD40aaFC57786b409A9ce571de375b4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooANY-FTM',
    earnedTokenAddress: '0xEE40C6Decc15dCeCE7a836e5CD5Ff94523D32265',
    earnContractAddress: '0xEE40C6Decc15dCeCE7a836e5CD5Ff94523D32265',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-any-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['ANY', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-dai-ftm',
    logo: 'fantom/DAI-FTM.png',
    name: 'DAI-FTM LP',
    token: 'DAI-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xe120ffBDA0d14f3Bb6d6053E90E63c572A66a428',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooDAI-FTM',
    earnedTokenAddress: '0x8316b990De26eB530B7B1bb0d87f5b0a304637cd',
    earnContractAddress: '0x8316b990De26eB530B7B1bb0d87f5b0a304637cd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-dai-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['DAI', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'ester-est-ftm',
    logo: 'fantom/EST-FTM.png',
    name: 'EST-FTM LP',
    token: 'EST-FTM LP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x0c9043cb1B994C8e4a8024e2F037Ea50b7025a82',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooEsterEST-FTM',
    earnedTokenAddress: '0x59247cfe6FC768A8C3F95601008B75A94F4939Bd',
    earnContractAddress: '0x59247cfe6FC768A8C3F95601008B75A94F4939Bd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ester-est-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Ester',
    assets: ['EST', 'FTM'],
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x181F3F22C9a751E2ce673498A03E1FDFC0ebBFB6',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/swap?outputCurrency=0x181f3f22c9a751e2ce673498a03e1fdfc0ebbfb6',
  },
  {
    id: 'ester-est',
    logo: 'fantom/EST.png',
    name: 'EST',
    token: 'EST',
    tokenDescription: 'Ester',
    tokenAddress: '0x181F3F22C9a751E2ce673498A03E1FDFC0ebBFB6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooEsterEST',
    earnedTokenAddress: '0x7076a33b6525132fF77F0FeE2daB2a1e79688DA0',
    earnContractAddress: '0x7076a33b6525132fF77F0FeE2daB2a1e79688DA0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'EST',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Ester',
    assets: ['EST'],
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/swap?outputCurrency=0x181f3f22c9a751e2ce673498a03e1fdfc0ebbfb6',
  },
  {
    id: 'boo-woofy-ftm',
    logo: 'fantom/WOOFY-FTM.png',
    name: 'WOOFY-FTM LP',
    token: 'WOOFY-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xfD0aB56B83130ce8f2b7A4f4d4532dEe495c0794',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooWOOFY-FTM',
    earnedTokenAddress: '0x7CE2332fAF6328986C75e3A8fCc1CB79621aeB1F',
    earnContractAddress: '0x7CE2332fAF6328986C75e3A8fCc1CB79621aeB1F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-woofy-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['WOOFY', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-bnb-ftm',
    logo: 'fantom/BNB-FTM.png',
    name: 'BNB-FTM LP',
    token: 'BNB-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x956DE13EA0FA5b577E4097Be837BF4aC80005820',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooBNB-FTM',
    earnedTokenAddress: '0xC5b2a6aB801E74F098aCC8Bb62B786b47319c4D9',
    earnContractAddress: '0xC5b2a6aB801E74F098aCC8Bb62B786b47319c4D9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-bnb-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['BNB', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'froyo-3pool',
    logo: 'fantom/FROYO-3Pool.png',
    name: 'USDT/DAI/USDC',
    token: 'FROYO 3Pool',
    tokenDescription: 'Froyo',
    tokenAddress: '0x4f85Bbf3B0265DCEd4Ec72ebD0358ccCf190F1B3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFroyo3Pool',
    earnedTokenAddress: '0xb18398ad9735fea37C97dcba50C0Bb947Dc0eeBD',
    earnContractAddress: '0xb18398ad9735fea37C97dcba50C0Bb947Dc0eeBD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'froyo-3pool',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Froyo',
    assets: ['USDT', 'DAI', 'USDC'],
    addLiquidityUrl: 'https://frozenyogurt.finance/pool',
  },
  {
    id: 'froyo-froyo-ftm',
    logo: 'fantom/FROYO.png',
    name: 'FROYO-FTM LP',
    token: 'FROYO-FTM LP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xBE0AF661BC9794E05fAb18C08C2C99811A0F45D2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFroyoFROYO-FTM',
    earnedTokenAddress: '0xeD4ea30c755676C07d3e0e0f74Ff84C0193B4551',
    earnContractAddress: '0xeD4ea30c755676C07d3e0e0f74Ff84C0193B4551',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'froyo-froyo-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Froyo',
    assets: ['FROYO', 'FTM'],
    addLiquidityUrl: 'https://app.sushi.com/add/ETH/0xA92d41Ab8eFeE617d80a829CD9F5683c5F793ADA',
    buyTokenUrl:
      'https://app.sushi.com/swap?outputCurrency=0xA92d41Ab8eFeE617d80a829CD9F5683c5F793ADA',
  },
  {
    id: 'boo-link-ftm',
    logo: 'fantom/LINK-FTM.png',
    name: 'LINK-FTM LP',
    token: 'LINK-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x89d9bC2F2d091CfBFc31e333D6Dc555dDBc2fd29',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooLINK-FTM',
    earnedTokenAddress: '0x711969A90C9EDD815A5C2b441FC80d067EC5E969',
    earnContractAddress: '0x711969A90C9EDD815A5C2b441FC80d067EC5E969',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-link-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['LINK', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-cover-ftm-eol',
    logo: 'fantom/COVER-FTM.png',
    name: 'COVER-FTM LP',
    token: 'COVER-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x5DC7848bF215F1D99F2AF3d2Bf78fcdf238EE34b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooCOVER-FTM',
    earnedTokenAddress: '0xF5Bf6b3624eb10Ee855FEC1C5a4af81D90996b6c',
    earnContractAddress: '0xF5Bf6b3624eb10Ee855FEC1C5a4af81D90996b6c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-cover-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SpookySwap',
    assets: ['COVER', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-cream-ftm',
    logo: 'fantom/CREAM-FTM.png',
    name: 'CREAM-FTM LP',
    token: 'CREAM-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xb77b223490e1f5951ec79A8d09Db9Eab2ADCB934',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooCREAM-FTM',
    earnedTokenAddress: '0x2BDA70d1DcE6b0855e812daaBB096F3DaaEBd4Bc',
    earnContractAddress: '0x2BDA70d1DcE6b0855e812daaBB096F3DaaEBd4Bc',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-cream-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['CREAM', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-snx-ftm',
    logo: 'fantom/SNX-FTM.png',
    name: 'SNX-FTM LP',
    token: 'SNX-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x06d173628bE105fE81F1C82c9979bA79eBCAfCB7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooSNX-FTM',
    earnedTokenAddress: '0x74907ad4E79b1Ce415caB26FEf526ae017598cEe',
    earnContractAddress: '0x74907ad4E79b1Ce415caB26FEf526ae017598cEe',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-snx-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['SNX', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-usdt-ftm',
    logo: 'fantom/USDT-FTM.png',
    name: 'fUSDT-FTM LP',
    token: 'fUSDT-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x5965E53aa80a0bcF1CD6dbDd72e6A9b2AA047410',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooUSDT-FTM',
    earnedTokenAddress: '0x5d89017d2465115007AbA00da1E6446dF2C19f34',
    earnContractAddress: '0x5d89017d2465115007AbA00da1E6446dF2C19f34',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-usdt-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['fUSDT', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-btc-ftm',
    logo: 'fantom/BTC-FTM.png',
    name: 'BTC-FTM LP',
    token: 'BTC-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xFdb9Ab8B9513Ad9E419Cf19530feE49d412C3Ee3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooBTC-FTM',
    earnedTokenAddress: '0xA3e3Af161943CfB3941B631676134bb048739727',
    earnContractAddress: '0xA3e3Af161943CfB3941B631676134bb048739727',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-btc-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['BTC', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-eth-ftm',
    logo: 'fantom/ETH-FTM.png',
    name: 'ETH-FTM LP',
    token: 'ETH-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xf0702249F4D3A25cD3DED7859a165693685Ab577',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooETH-FTM',
    earnedTokenAddress: '0x2a30C5e0d577108F694d2A96179cd73611Ee069b',
    earnContractAddress: '0x2a30C5e0d577108F694d2A96179cd73611Ee069b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-eth-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['ETH', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-aave-ftm',
    logo: 'fantom/AAVE-FTM.png',
    name: 'AAVE-FTM LP',
    token: 'AAVE-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xeBF374bB21D83Cf010cC7363918776aDF6FF2BF6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooAAVE-FTM',
    earnedTokenAddress: '0xDa4bb93Bac7CC00F6c6e2193d115Cf45099b31a0',
    earnContractAddress: '0xDa4bb93Bac7CC00F6c6e2193d115Cf45099b31a0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-aave-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['AAVE', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
  {
    id: 'boo-crv-ftm',
    logo: 'fantom/CRV-FTM.png',
    name: 'CRV-FTM LP',
    token: 'CRV-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xB471Ac6eF617e952b84C6a9fF5de65A9da96C93B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooCRV-FTM',
    earnedTokenAddress: '0xdf68Bf80D427A5827Ff2c06A9c70D407e17DC041',
    earnContractAddress: '0xdf68Bf80D427A5827Ff2c06A9c70D407e17DC041',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-crv-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['CRV', 'FTM'],
    addLiquidityUrl: 'https://spookyswap.finance/add',
    buyTokenUrl: 'https://spookyswap.finance/swap',
  },
];
