export const avalanchePools = [
  {
    id: 'avax-bifi-maxi',
    logo: 'single-assets/BIFI.png',
    name: 'BIFI Maxi',
    token: 'BIFI',
    tokenDescription: 'Beefy.Finance',
    tokenAddress: '0xd6070ae98b8069de6B494332d1A1a81B6179D960',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAvalancheBIFI',
    earnedTokenAddress: '0xCeefB07Ad37ff165A0b03DC7C808fD2E2fC77683',
    earnContractAddress: '0xCeefB07Ad37ff165A0b03DC7C808fD2E2fC77683',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BIFI',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beefy.Finance',
    assets: ['BIFI'],
    withdrawalFee: '0.05%',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xd6070ae98b8069de6B494332d1A1a81B6179D960',
  },
  {
    id: 'joe-joe',
    logo: 'single-assets/JOE.png',
    name: 'JOE',
    token: 'JOE',
    tokenDescription: 'Trader Joe (xJoe)',
    tokenAddress: '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoe',
    earnedTokenAddress: '0x282B11E65f0B49363D4505F91c7A44fBEe6bCc0b',
    earnContractAddress: '0x282B11E65f0B49363D4505F91c7A44fBEe6bCc0b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'JOE',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['JOE'],
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-joe-usdc.e',
    name: 'JOE-USDC.e LP',
    token: 'JOE-USDC.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x67926d973cD8eE876aD210fAaf7DFfA99E414aCf',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeJOE-USDC.e',
    earnedTokenAddress: '0xea75ff4F580418A1430Bd3EBaF26B03C096D9489',
    earnContractAddress: '0xea75ff4F580418A1430Bd3EBaF26B03C096D9489',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-joe-usdc.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['JOE', 'USDCe'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-wavax-aave.e',
    name: 'AAVE.e-AVAX LP',
    token: 'AAVE.e-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xc3e6D9f7a3A5E3e223356383C7C055Ee3F26A34F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-AAVE.e',
    earnedTokenAddress: '0xaF5D5a21E6d53b96daf37EC9fC9360c5b6B4AeBE',
    earnContractAddress: '0xaF5D5a21E6d53b96daf37EC9fC9360c5b6B4AeBE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-aave.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['AAVEe', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0x63a72806098bd3d9520cc43356dd78afe5d386d9/AVAX',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-mai-wavax',
    name: 'MAI-AVAX LP',
    token: 'MAI-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xD6d03fe131dB3dE3aF5E6326036BaC4C1Cf8C80d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeMAI-AVAX',
    earnedTokenAddress: '0xe419f17821F10Ce741F67232406F6a8585d52005',
    earnContractAddress: '0xe419f17821F10Ce741F67232406F6a8585d52005',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-mai-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['MAI', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0x3b55e45fd6bd7d4724f5c47e0d1bcaedd059263e/AVAX',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-weth.e-usdc.e',
    name: 'WETH.e-USDC.e LP',
    token: 'WETH.e-USDC.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x199fb78019A08af2Cb6a078409D0C8233Eba8a0c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeWETH.e-USDC.e',
    earnedTokenAddress: '0xA4D0b2100107479dd637526F9296D34D8CBdde13',
    earnContractAddress: '0xA4D0b2100107479dd637526F9296D34D8CBdde13',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-weth.e-usdc.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['WETHe', 'USDCe'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-wbtc.e-usdc.e',
    name: 'WBTC.e-USDC.e LP',
    token: 'WBTC.e-USDC.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x62475f52aDd016A06B398aA3b2C2f2E540d36859',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeWBTC.e-USDC.e',
    earnedTokenAddress: '0x0D6f7D5Bf8406FAaa1eeB66756f684dBc6790350',
    earnContractAddress: '0x0D6f7D5Bf8406FAaa1eeB66756f684dBc6790350',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wbtc.e-usdc.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['WBTCe', 'USDCe'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0x50b7545627a5162f82a992c33b87adc75187b218/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-wavax-bifi',
    name: 'BIFI-AVAX LP',
    token: 'BIFI-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x361221991B3B6282fF3a62Bc874d018bfAF1f8C8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeWAVAX-BIFI',
    earnedTokenAddress: '0xb1e29194d90d67b8d1c4104FDf6DaF0F7d3344D5',
    earnContractAddress: '0xb1e29194d90d67b8d1c4104FDf6DaF0F7d3344D5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-bifi',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['BIFI', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/AVAX/0xd6070ae98b8069de6b494332d1a1a81b6179d960',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-bnb-wavax',
    name: 'BNB-AVAX LP',
    token: 'BNB-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xeb8eB6300c53C3AddBb7382Ff6c6FbC4165B0742',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeBNB-WAVAX',
    earnedTokenAddress: '0x2b7066770EF90eb7faCFB6cbe6A975C91FA13151',
    earnContractAddress: '0x2b7066770EF90eb7faCFB6cbe6A975C91FA13151',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-bnb-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['BNB', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0x264c1383ea520f73dd837f915ef3a732e204a493/AVAX',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-usdc.e-usdt.e',
    name: 'USDC.e-USDT.e LP',
    token: 'USDC.e-USDT.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x2E02539203256c83c7a9F6fA6f8608A32A2b1Ca2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUSDC.e-USDT.e',
    earnedTokenAddress: '0xE37CeD3b5FBeDb523a48eB4eA3c3e13b2092352a',
    earnContractAddress: '0xE37CeD3b5FBeDb523a48eB4eA3c3e13b2092352a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-usdc.e-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['USDCe', 'USDTe'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664/0xc7198437980c041c805a1edcba50c1ce5db95118',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-usdt.e-dai.e',
    name: 'USDT.e-DAI.e LP',
    token: 'USDT.e-DAI.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xa6908C7E3Be8F4Cd2eB704B5cB73583eBF56Ee62',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUSDT.e-DAI.e',
    earnedTokenAddress: '0x95D11A45B0B55F96D75f70d63EBf95a824cD836D',
    earnContractAddress: '0x95D11A45B0B55F96D75f70d63EBf95a824cD836D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-usdt.e-dai.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['USDTe', 'DAIe'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0xc7198437980c041c805a1edcba50c1ce5db95118/0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-wavax-usdt.e',
    name: 'USDT.e-AVAX LP',
    token: 'USDT.e-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xeD8CBD9F0cE3C6986b22002F03c6475CEb7a6256',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeWAVAX-USDT.e',
    earnedTokenAddress: '0xc1e960785292E68a027FC88042e3ec1A5A064B3C',
    earnContractAddress: '0xc1e960785292E68a027FC88042e3ec1A5A064B3C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['USDTe', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/AVAX/0xc7198437980c041c805a1edcba50c1ce5db95118',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-link.e-wavax',
    name: 'LINK.e-AVAX LP',
    token: 'LINK.e-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x6F3a0C89f611Ef5dC9d96650324ac633D02265D3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeLINK.e-WAVAX',
    earnedTokenAddress: '0x7cB719CD18E171c3db4607536804f34A01AE506F',
    earnContractAddress: '0x7cB719CD18E171c3db4607536804f34A01AE506F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-link.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['LINKe', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0x5947bb275c521040051d82396192181b413227a3/AVAX',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-wavax-spell',
    name: 'SPELL-AVAX LP',
    token: 'SPELL-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x62cf16BF2BC053E7102E2AC1DEE5029b94008d99',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeSPELL-WAVAX',
    earnedTokenAddress: '0x3E25CcCD4aC11d4F417b992285188714E80BAE8C',
    earnContractAddress: '0x3E25CcCD4aC11d4F417b992285188714E80BAE8C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-spell',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['SPELL', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/0xCE1bFFBD5374Dac86a2893119683F4911a2F7814',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-frax-wavax',
    name: 'FRAX-AVAX LP',
    token: 'FRAX-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x0d84595e8638dBc631076c51000B2d31120D8aa1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeFRAX-WAVAX',
    earnedTokenAddress: '0xdAfD1803956c0eb7D85d475eb10D69Baa207C72d',
    earnContractAddress: '0xdAfD1803956c0eb7D85d475eb10D69Baa207C72d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-frax-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['FRAX', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/0xDC42728B0eA910349ed3c6e1c9Dc06b5FB591f98',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-time-wavax',
    name: 'TIME-AVAX LP',
    token: 'TIME-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xf64e1c5B6E17031f5504481Ac8145F4c3eab4917',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeTIME-AVAX',
    earnedTokenAddress: '0x4502e2F6802D48578f76920e5D56557459C04B7D',
    earnContractAddress: '0x4502e2F6802D48578f76920e5D56557459C04B7D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-time-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['TIME', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/0xb54f16fB19478766A268F172C9480f8da1a7c9C3',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'pangolin-png',
    logo: 'single-assets/PNG.svg',
    name: 'PNG',
    token: 'PNG',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x60781C2586D68229fde47564546784ab3fACA982',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinPNG',
    earnedTokenAddress: '0x670AF270FeE3BbC89e32DDd7B8ec43663A910793',
    earnContractAddress: '0x670AF270FeE3BbC89e32DDd7B8ec43663A910793',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'PNG',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['PNG'],
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x60781C2586D68229fde47564546784ab3fACA982',
  },
  {
    id: 'joe-mai-usdc.e',
    name: 'MAI-USDC.e LP',
    token: 'MAI-USDC.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x58f75d7745ec383491053947Cd2AE6Ee7fc8B8f8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeMAI-USDC.e',
    earnedTokenAddress: '0xaa5a5AD8a27fEd7F791952705ce90134eac620dc',
    earnContractAddress: '0xaa5a5AD8a27fEd7F791952705ce90134eac620dc',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-mai-usdc.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['MAI', 'USDCe'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0x3b55e45fd6bd7d4724f5c47e0d1bcaedd059263e/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-joe-wavax',
    name: 'JOE-AVAX LP',
    token: 'JOE-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x454E67025631C065d3cFAD6d71E6892f74487a15',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeJOE-AVAX',
    earnedTokenAddress: '0x3D81A269E05e6057e4dF9E2D76E254E65a65Eb66',
    earnContractAddress: '0x3D81A269E05e6057e4dF9E2D76E254E65a65Eb66',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-joe-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['JOE', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/AVAX/0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-joe-usdt.e',
    name: 'JOE-USDT.e LP',
    token: 'JOE-USDT.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x1643de2efB8e35374D796297a9f95f64C082a8ce',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeJOE-USDT.e',
    earnedTokenAddress: '0xD0B0B19f2DC29a17175A2afc47b29C6DDd74d3D4',
    earnContractAddress: '0xD0B0B19f2DC29a17175A2afc47b29C6DDd74d3D4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-joe-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['JOE', 'USDTe'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/AVAX/0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-mim-wavax',
    name: 'MIM-AVAX LP',
    token: 'MIM-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x781655d802670bbA3c89aeBaaEa59D3182fD755D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeMIM-WAVAX',
    earnedTokenAddress: '0xb387376366C479Ac1d97833c10cFe738CC786dd1',
    earnContractAddress: '0xb387376366C479Ac1d97833c10cFe738CC786dd1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-mim-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['MIM', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/AVAX/0x130966628846BFd36ff31a822705796e8cb8C18D',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-qi-wavax',
    name: 'QI-AVAX LP',
    token: 'QI-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x2774516897AC629aD3ED9dCac7e375Dda78412b9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeQI-WAVAX',
    earnedTokenAddress: '0x1CA786F754D339797BBecE959799fBA063C22F44',
    earnContractAddress: '0x1CA786F754D339797BBecE959799fBA063C22F44',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-qi-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['aQI', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/AVAX/0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-syn-wavax',
    name: 'SYN-AVAX LP',
    token: 'SYN-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x20ABdC20758990b6afc90dA2f2D30CD0aa3F73c6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeSYN-WAVAX',
    earnedTokenAddress: '0x9b50B06B81f033ca86D70F0a44F30BD7E0155737',
    earnContractAddress: '0x9b50B06B81f033ca86D70F0a44F30BD7E0155737',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-syn-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['SYN', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/AVAX/0x1f1E7c893855525b303f99bDF5c3c05Be09ca251',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-weth.e-usdt.e',
    name: 'WETH.e-USDT.e LP',
    token: 'WETH.e-USDT.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xbe1b87f47fDE3F338Aa3AA98b85435e1709dFD06',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUSDT.e-WETH.e',
    earnedTokenAddress: '0x5d06127cCfc6b1C057c3b4494B980B7c2d556360',
    earnContractAddress: '0x5d06127cCfc6b1C057c3b4494B980B7c2d556360',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-weth.e-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['WETHe', 'USDTe'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0xc7198437980c041c805A1EDcbA50c1Ce5db95118/0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-wbtc.e-usdt.e',
    name: 'WBTC.e-USDT.e LP',
    token: 'WBTC.e-USDT.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xB8D5E8a9247db183847c7D79af9C67F6aeF759f7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUSDT.e-WBTC.e',
    earnedTokenAddress: '0x3f5E367AC9Fb78A21Dc83d96dc1477eDE1F6219D',
    earnContractAddress: '0x3f5E367AC9Fb78A21Dc83d96dc1477eDE1F6219D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wbtc.e-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['WBTCe', 'USDTe'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0xc7198437980c041c805A1EDcbA50c1Ce5db95118/0x50b7545627a5162F82A992c33b87aDc75187B218',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-wbtc.e-wavax',
    name: 'WBTC.e-AVAX LP',
    token: 'WBTC.e-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xd5a37dC5C9A396A03dd1136Fc76A1a02B1c88Ffa',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeWBTC.e-AVAX',
    earnedTokenAddress: '0xf32E23EB10350e381aA8b775d381e27C50a9195f',
    earnContractAddress: '0xf32E23EB10350e381aA8b775d381e27C50a9195f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wbtc.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['WBTCe', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/AVAX/0x50b7545627a5162F82A992c33b87aDc75187B218',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-weth.e-wavax',
    name: 'WETH.e-AVAX LP',
    token: 'WETH.e-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xFE15c2695F1F920da45C30AAE47d11dE51007AF9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeWETH.e-AVAX',
    earnedTokenAddress: '0x68866acc5C940938B373F55F7009f824c7662F5B',
    earnContractAddress: '0x68866acc5C940938B373F55F7009f824c7662F5B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-weth.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['WETHe', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/AVAX/0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-usdc.e-dai.e',
    name: 'USDC.e-DAI.e LP',
    token: 'USDC.e-DAI.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x63ABE32d0Ee76C05a11838722A63e012008416E6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUSDC.e-DAI.e',
    earnedTokenAddress: '0x8EdD09d683bD30baCB0EF6040160997Afe7d36c0',
    earnContractAddress: '0x8EdD09d683bD30baCB0EF6040160997Afe7d36c0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-usdc.e-dai.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['USDCe', 'DAIe'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0xd586E7F844cEa2F87f50152665BCbc2C279D8d70/0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-usdc.e-wavax',
    name: 'USDC.e-AVAX LP',
    token: 'USDC.e-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xA389f9430876455C36478DeEa9769B7Ca4E3DDB1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUSDC.e-AVAX',
    earnedTokenAddress: '0xdD63306A9792Ecbd1cd6baED3f1b18BEA638aaCe',
    earnContractAddress: '0xdD63306A9792Ecbd1cd6baED3f1b18BEA638aaCe',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-usdc.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['USDCe', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/AVAX/0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'joe-wavax-dai.e',
    name: 'DAI.e-AVAX LP',
    token: 'DAI.e-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x87Dee1cC9FFd464B79e058ba20387c1984aed86a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeDAI.e-AVAX',
    earnedTokenAddress: '0x71d57De132b3a090BFF5027A1e88947Bed1a32ba',
    earnContractAddress: '0x71d57De132b3a090BFF5027A1e88947Bed1a32ba',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-dai.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['DAIe', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/AVAX/0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },
  {
    id: 'pangolin-usdc.e-wavax',
    name: 'USDC.e-AVAX LP',
    token: 'USDC.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xbd918Ed441767fe7924e99F6a0E0B568ac1970D9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUSDC.e-AVAX',
    earnedTokenAddress: '0x8eb23a3010795574eE3DD101843dC90bD63b5099',
    earnContractAddress: '0x8eb23a3010795574eE3DD101843dC90bD63b5099',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-usdc.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['USDCe', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
  },
  {
    id: 'olive-dai.e-usdt.e',
    name: 'DAI.e-USDT.e LP',
    token: 'DAI.e-USDT.e LP',
    tokenDescription: 'OliveSwap',
    tokenAddress: '0x4758441C1F5d067839776456033f6cd0EB9fb3d3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOliveDAIe-USDTe',
    earnedTokenAddress: '0xabd4a1aDaC20ff42d9946EF5ec5FbF822Ccd5B74',
    earnContractAddress: '0xabd4a1aDaC20ff42d9946EF5ec5FbF822Ccd5B74',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'olive-dai.e-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OliveSwap',
    assets: ['DAIe', 'USDTe'],
    addLiquidityUrl:
      'https://swap.olive.cash/#/add/0xd586E7F844cEa2F87f50152665BCbc2C279D8d70/0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
  },
  {
    id: 'olive-busd.e-usdt.e',
    name: 'BUSD.e-USDT.e LP',
    token: 'BUSD.e-USDT.e LP',
    tokenDescription: 'OliveSwap',
    tokenAddress: '0x9e4562caC6b91f5154a42e6270F2Ea58A4149b3E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOliveBUSDe-USDTe',
    earnedTokenAddress: '0x2913966e88490B5a65D67298801c0c51f377BB9C',
    earnContractAddress: '0x2913966e88490B5a65D67298801c0c51f377BB9C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'olive-busd.e-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OliveSwap',
    assets: ['BUSDe', 'USDTe'],
    addLiquidityUrl:
      'https://swap.olive.cash/#/add/0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98/0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
  },
  {
    id: 'pangolin-png-qi',
    name: 'QI-PNG LP',
    token: 'QI-PNG LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x50E7e19281a80E3C24a07016eDB87EbA9fe8C6cA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinQI-PNG',
    earnedTokenAddress: '0xB297EC6eb512d646f558F6514e85aa59cD583a1F',
    earnContractAddress: '0xB297EC6eb512d646f558F6514e85aa59cD583a1F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-png-qi',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['aQI', 'PNG'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x60781C2586D68229fde47564546784ab3fACA982/0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
  },
  {
    id: 'pangolin-qi-wavax',
    name: 'QI-AVAX LP',
    token: 'QI-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xE530dC2095Ef5653205CF5ea79F8979a7028065c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinQI-AVAX',
    earnedTokenAddress: '0x052B006A2CfB2523042b3041f15adC7fa5356312',
    earnContractAddress: '0x052B006A2CfB2523042b3041f15adC7fa5356312',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-qi-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['aQI', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
  },
  {
    id: 'pangolin-uni.e-wavax',
    name: 'UNI.e-AVAX LP',
    token: 'UNI.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x99dD520748eB0355c69DAE2692E4615C8Ab031ce',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUNI.e-AVAX',
    earnedTokenAddress: '0x48cc86214C58d7EaA78C100156c55DD45A676Ed1',
    earnContractAddress: '0x48cc86214C58d7EaA78C100156c55DD45A676Ed1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-uni.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['UNIe', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x8eBAf22B6F053dFFeaf46f4Dd9eFA95D89ba8580',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x8eBAf22B6F053dFFeaf46f4Dd9eFA95D89ba8580',
  },
  {
    id: 'pangolin-yfi.e-wavax',
    name: 'YFI.e-AVAX LP',
    token: 'YFI.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x9a634CE657681200B8c5fb3Fa1aC59Eb0662f45C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinYFI.e-AVAX',
    earnedTokenAddress: '0xa640E017Fc01dD39F6b7B07b0460B04E218c0a01',
    earnContractAddress: '0xa640E017Fc01dD39F6b7B07b0460B04E218c0a01',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-yfi.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['YFIe', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x9eAaC1B23d935365bD7b542Fe22cEEe2922f52dc',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x9eAaC1B23d935365bD7b542Fe22cEEe2922f52dc',
  },
  {
    id: 'pangolin-aave.e-wavax',
    name: 'AAVE.e-AVAX LP',
    token: 'AAVE.e-AVAXLP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x5944f135e4F1E3fA2E5550d4B5170783868cc4fE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinAAVE.e-AVAX',
    earnedTokenAddress: '0xd9fe7Ff89C5303E439a14a5155F7F48E34F28518',
    earnContractAddress: '0xd9fe7Ff89C5303E439a14a5155F7F48E34F28518',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-aave.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['AAVEe', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x63a72806098Bd3D9520cC43356dD78afe5D386D9',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x63a72806098Bd3D9520cC43356dD78afe5D386D9',
  },
  {
    id: 'pangolin-sushi.e-wavax',
    name: 'SUSHI.e-AVAX LP',
    token: 'SUSHI.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xF62381AFFdfd27Dba91A1Ea2aCf57d426E28c341',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinSUSHI.e-AVAX',
    earnedTokenAddress: '0xFa7767Cf402a6D198cC136FED32550ABA70C3e02',
    earnContractAddress: '0xFa7767Cf402a6D198cC136FED32550ABA70C3e02',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-sushi.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['SUSHIe', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x37B608519F91f70F2EeB0e5Ed9AF4061722e4F76',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x37B608519F91f70F2EeB0e5Ed9AF4061722e4F76',
  },
  {
    id: 'pangolin-wavax-xava',
    name: 'XAVA-AVAX LP',
    token: 'XAVA-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x42152bDD72dE8d6767FE3B4E17a221D6985E8B25',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinXAVA-AVAX',
    earnedTokenAddress: '0xC556387AEA8B0E55f672edf15FEc3acbcF45dC17',
    earnContractAddress: '0xC556387AEA8B0E55f672edf15FEc3acbcF45dC17',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-wavax-xava',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['XAVA', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x5947BB275c521040051D82396192181b413227A3',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x5947BB275c521040051D82396192181b413227A3',
  },
  {
    id: 'pangolin-png-usdt.e',
    name: 'USDT.e-PNG LP',
    token: 'USDT.e-PNG LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x1fFB6ffC629f5D820DCf578409c2d26A2998a140',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUSDT.e-PNG',
    earnedTokenAddress: '0x97e860CE03ae3da20Ba9740b8dA90036EE891f81',
    earnContractAddress: '0x97e860CE03ae3da20Ba9740b8dA90036EE891f81',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-png-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['USDTe', 'PNG'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x60781c2586d68229fde47564546784ab3faca982/0xc7198437980c041c805a1edcba50c1ce5db95118',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
  },
  {
    id: 'pangolin-weth.e-png',
    name: 'WETH.e-PNG LP',
    token: 'WETH.e-PNG LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xcf35400A595EFCF0Af591D3Aeb5a35cBCD120d54',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinWETH.e-PNG',
    earnedTokenAddress: '0xa66DfCBdAfbe4B4D62535f64f1C2Fb50FF42E4C6',
    earnContractAddress: '0xa66DfCBdAfbe4B4D62535f64f1C2Fb50FF42E4C6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-weth.e-png',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['WETHe', 'PNG'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
  },
  {
    id: 'pangolin-wbtc.e-png',
    name: 'WBTC.e-PNG LP',
    token: 'WBTC.e-PNG LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xf277e270bc7664E6EBba19620530b83883748a13',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinWBTC.e-PNG',
    earnedTokenAddress: '0xd15db63C47Df2F669ef1560b63fF0aD6996960D4',
    earnContractAddress: '0xd15db63C47Df2F669ef1560b63fF0aD6996960D4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-wbtc.e-png',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['WBTCe', 'PNG'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x50b7545627a5162F82A992c33b87aDc75187B218',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x50b7545627a5162F82A992c33b87aDc75187B218',
  },
  {
    id: 'pangolin-wavax-dai.e',
    name: 'DAI.e-AVAX LP',
    token: 'DAI.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xbA09679Ab223C6bdaf44D45Ba2d7279959289AB0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinDAI.e-AVAX',
    earnedTokenAddress: '0x97102bAdf07DA7af61594b686fe311d06930B76e',
    earnContractAddress: '0x97102bAdf07DA7af61594b686fe311d06930B76e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-wavax-dai.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['DAIe', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
  },
  {
    id: 'pangolin-link.e-wavax',
    name: 'LINK.e-AVAX LP',
    token: 'LINK.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x5875c368Cddd5FB9Bf2f410666ca5aad236DAbD4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinLINK.e-AVAX',
    earnedTokenAddress: '0x2bAe08Cf46867b6c29d6bcA000Dc43dFCCAc73E3',
    earnContractAddress: '0x2bAe08Cf46867b6c29d6bcA000Dc43dFCCAc73E3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-link.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['LINKe', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x5947BB275c521040051D82396192181b413227A3',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x5947BB275c521040051D82396192181b413227A3',
  },
  {
    id: 'pangolin-weth.e-wavax',
    name: 'WETH.e-AVAX LP',
    token: 'WETH.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x7c05d54fc5CB6e4Ad87c6f5db3b807C94bB89c52',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinWETH.e-AVAX',
    earnedTokenAddress: '0xc98fF20bB8cd7C68C483c7C66f36e3F69ad81B47',
    earnContractAddress: '0xc98fF20bB8cd7C68C483c7C66f36e3F69ad81B47',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-weth.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['WETHe', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
  },
  {
    id: 'pangolin-wavax-usdt.e',
    name: 'USDT.e-AVAX LP',
    token: 'USDT.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xe28984e1EE8D431346D32BeC9Ec800Efb643eef4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUSDT.e-AVAX',
    earnedTokenAddress: '0xC24c73C256d527726a7a012e1360c78d0cc02eEA',
    earnContractAddress: '0xC24c73C256d527726a7a012e1360c78d0cc02eEA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-wavax-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['USDTe', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
  },
  {
    id: 'pangolin-wbtc.e-wavax',
    name: 'WBTC.e-AVAX LP',
    token: 'WBTC.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x5764b8D8039C6E32f1e5d8DE8Da05DdF974EF5D3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinWBTC.e-AVAX',
    earnedTokenAddress: '0xBBBe0fA93c8ced0614351bAF74979aB1243cF9c8',
    earnContractAddress: '0xBBBe0fA93c8ced0614351bAF74979aB1243cF9c8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-wbtc.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['WBTCe', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x50b7545627a5162F82A992c33b87aDc75187B218',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x50b7545627a5162F82A992c33b87aDc75187B218',
  },
  {
    id: 'png-bifi-png',
    name: 'BIFI-PNG LP',
    token: 'BIFI-PNG LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x7D1F298D41A03eda3AD9CbB06CA061Ba60b4BdAe',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinBIFI-PNG',
    earnedTokenAddress: '0x51E73582751A633630abcB13CD77d25958C178f5',
    earnContractAddress: '0x51E73582751A633630abcB13CD77d25958C178f5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-bifi-png',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['BIFI', 'PNG'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x60781c2586d68229fde47564546784ab3faca982/0xd6070ae98b8069de6B494332d1A1a81B6179D960',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0x60781c2586d68229fde47564546784ab3faca982&outputCurrency=0xd6070ae98b8069de6B494332d1A1a81B6179D960',
  },
  {
    id: 'png-bifi-avax',
    name: 'BIFI-AVAX LP',
    token: 'BIFI-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xAaCE68f9C8506610929D76a0729c7C24603641fC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinBIFI-AVAX',
    earnedTokenAddress: '0x4a9F72415A159753ee1eC85edBf4D212627762a9',
    earnContractAddress: '0x4a9F72415A159753ee1eC85edBf4D212627762a9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-bifi-avax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['BIFI', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7/0xd6070ae98b8069de6B494332d1A1a81B6179D960',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xd6070ae98b8069de6B494332d1A1a81B6179D960',
  },
  {
    id: 'png-bnb-png-eol',
    name: 'BNB-PNG LP',
    token: 'BNB-PNG LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x76BC30aCdC88b2aD2e8A5377e59ed88c7f9287f9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinBNB-PNG',
    earnedTokenAddress: '0xF62B808de461A735B4793179714adb573389D919',
    earnContractAddress: '0xF62B808de461A735B4793179714adb573389D919',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-bnb-png',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['BNB', 'PNG'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x60781c2586d68229fde47564546784ab3faca982/0x264c1383EA520f73dd837F915ef3a732e204a493',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0x60781c2586d68229fde47564546784ab3faca982&outputCurrency=0x264c1383EA520f73dd837F915ef3a732e204a493',
  },
  {
    id: 'png-bnb-avax',
    name: 'BNB-AVAX LP',
    token: 'BNB-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xF776Ef63c2E7A81d03e2c67673fd5dcf53231A3f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinBNB-AVAX',
    earnedTokenAddress: '0x0E97d2E7DdD8708b99C7dd57C68B490B511b12Da',
    earnContractAddress: '0x0E97d2E7DdD8708b99C7dd57C68B490B511b12Da',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-bnb-avax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['BNB', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7/0x264c1383EA520f73dd837F915ef3a732e204a493',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x408D4cD0ADb7ceBd1F1A1C33A0Ba2098E1295bAB',
  },
  {
    id: 'png-wbtc-png-eol',
    name: 'WBTC-PNG LP',
    token: 'WBTC-PNG LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xf372ceAE6B2F4A2C4A6c0550044A7eab914405ea',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinWBTC-PNG',
    earnedTokenAddress: '0xAa1ACf9939e98C09f71f21DdD956Ebc704A57027',
    earnContractAddress: '0xAa1ACf9939e98C09f71f21DdD956Ebc704A57027',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-wbtc-png',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['WBTC', 'PNG'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x60781c2586d68229fde47564546784ab3faca982/0x408D4cD0ADb7ceBd1F1A1C33A0Ba2098E1295bAB',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0x60781c2586d68229fde47564546784ab3faca982&outputCurrency=0x408D4cD0ADb7ceBd1F1A1C33A0Ba2098E1295bAB',
  },
  {
    id: 'png-dai-png-eol',
    name: 'DAI-PNG LP',
    token: 'DAI-PNG LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xD765B31399985f411A9667330764f62153b42C76',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinDAI-PNG',
    earnedTokenAddress: '0xC1CdB110332055BE7F4D961833D7D21E7ED3028D',
    earnContractAddress: '0xC1CdB110332055BE7F4D961833D7D21E7ED3028D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-dai-png',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['DAI', 'PNG'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x60781c2586d68229fde47564546784ab3faca982/0xbA7dEebBFC5fA1100Fb055a87773e1E99Cd3507a',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0x60781c2586d68229fde47564546784ab3faca982&outputCurrency=0xbA7dEebBFC5fA1100Fb055a87773e1E99Cd3507a',
  },
  {
    id: 'gondola-usdt-zusdt-eol',
    logo: 'single-assets/USDT.svg',
    name: 'zUSDT-USDT LP',
    token: 'zUSDT-USDT LP',
    tokenDescription: 'Gondola',
    tokenAddress: '0xE586dB7Db75B87A3E84110a73b99960F5f106c6A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGondolaZUSDT-USDT',
    earnedTokenAddress: '0xEbAE4810Fd13605957e7D90bd49d2fD8F8148923',
    earnContractAddress: '0xEbAE4810Fd13605957e7D90bd49d2fD8F8148923',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'gondola-usdt-zusdt',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Gondola',
    assets: ['zUSDT', 'USDT'],
    addLiquidityUrl: 'https://app.gondola.finance/#/deposit/usdt',
    buyTokenUrl: 'https://app.gondola.finance/#/swap/usdt',
  },
  {
    id: 'lyd-lyd-usdt-eol',
    logo: 'usdt-pairs/LYD-USDT.png',
    name: 'LYD-USDT LP',
    token: 'LYD-USDT LP',
    tokenDescription: 'Lydia',
    tokenAddress: '0x752C59f22fAAA861108649F4596034796C69bC3f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooLydiaLYD-USDT',
    earnedTokenAddress: '0xc44d493B6219A7f5C286724b74c158CEBd7fB6f7',
    earnContractAddress: '0xc44d493B6219A7f5C286724b74c158CEBd7fB6f7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'lyd-lyd-usdt',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Lydia',
    assets: ['LYD', 'USDT'],
    addLiquidityUrl:
      'https://www.lydia.finance/#/add/0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084/0xde3a24028580884448a5397872046a019649b084',
    buyTokenUrl:
      'https://exchange.lydia.finance/#/swap?inputCurrency=0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084&outputCurrency=0xde3a24028580884448a5397872046a019649b084',
  },
  {
    id: 'lyd-lyd-avax',
    logo: 'avax-pairs/LYD-AVAX.png',
    name: 'LYD-AVAX LP',
    token: 'LYD-AVAX LP',
    tokenDescription: 'Lydia',
    tokenAddress: '0xFba4EdaAd3248B03f1a3261ad06Ad846A8e50765',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooLydiaLYD-AVAX',
    earnedTokenAddress: '0x994aB71F95A8de4dAaF6DE3D9862284693fB2bDf',
    earnContractAddress: '0x994aB71F95A8de4dAaF6DE3D9862284693fB2bDf',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'lyd-lyd-avax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Lydia',
    assets: ['LYD', 'AVAX'],
    addLiquidityUrl:
      'https://www.lydia.finance/#/add/0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    buyTokenUrl:
      'https://exchange.lydia.finance/#/swap?inputCurrency=0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084&outputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
  },
  {
    id: 'olive-olive-avax',
    logo: 'avax-pairs/OLIVE-AVAX.png',
    name: 'OLIVE-AVAX LP',
    token: 'OLIVE-AVAX LP',
    tokenDescription: 'OliveSwap',
    tokenAddress: '0x57cc32Cd7F5a531953E9af25e1C9394093428082',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOliveOLIVE-AVAX',
    earnedTokenAddress: '0x9Bb9B11917e7bC3528B6048E9B01cD5E302bb27B',
    earnContractAddress: '0x9Bb9B11917e7bC3528B6048E9B01cD5E302bb27B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'olive-olive-avax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OliveSwap',
    assets: ['OLIVE', 'AVAX'],
    addLiquidityUrl:
      'https://swap.olive.cash/#/add/AVAX/0x617724974218A18769020A70162165A539c07E8a',
    buyTokenUrl:
      'https://swap.olive.cash/#/swap?outputCurrency=0x617724974218A18769020A70162165A539c07E8a',
  },
  {
    id: 'olive-olive-usdt-eol',
    logo: 'avax-pairs/OLIVE-USDT.png',
    name: 'OLIVE-USDT LP',
    token: 'OLIVE-USDT LP',
    tokenDescription: 'OliveSwap',
    tokenAddress: '0xF54a719215622f602FCA5BF5a6509734C3574a4c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOliveOLIVE-USDT',
    earnedTokenAddress: '0xBdA8bC79705BC60226adCA2766e94Eb5512949a3',
    earnContractAddress: '0xBdA8bC79705BC60226adCA2766e94Eb5512949a3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'olive-olive-usdt',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'OliveSwap',
    assets: ['OLIVE', 'USDT'],
    addLiquidityUrl:
      'https://swap.olive.cash/#/add/0xde3A24028580884448a5397872046a019649b084/0x617724974218A18769020A70162165A539c07E8a',
    buyTokenUrl:
      'https://swap.olive.cash/#/swap?outputCurrency=0x617724974218A18769020A70162165A539c07E8a',
  },

  {
    id: 'snob-3pool-eol',
    logo: 'avax-pairs/SNOB3P.png',
    name: 'USDT/BUSD/DAI',
    token: 'Snob 3Pool',
    tokenDescription: 'Snowball',
    tokenAddress: '0xdE1A11C331a0E45B9BA8FeE04D4B51A745f1e4A4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSnob3Pool',
    earnedTokenAddress: '0x0a350c62f4b8C7dA93fBca469e53a182b5BBD044',
    earnContractAddress: '0x0a350c62f4b8C7dA93fBca469e53a182b5BBD044',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'snob-3pool',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Snowball',
    assets: ['USDT', 'BUSD', 'DAI'],
    addLiquidityUrl: 'https://snowball.network/stablevault/',
  },

  {
    id: 'com-usdt-avax-eol',
    logo: 'avax-pairs/USDT-AVAX.png',
    name: 'USDT-AVAX LP',
    token: 'USDT-AVAX CLP',
    tokenDescription: 'Complus',
    tokenAddress: '0xe64bfAe83BA234aB85BceF5B7A92427E29b3AA11',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComplusUSDT-AVAX',
    earnedTokenAddress: '0x14d07853560436aEe38BE12DD66d944B07D5E59F',
    earnContractAddress: '0x14d07853560436aEe38BE12DD66d944B07D5E59F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'com-usdt-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Complus',
    assets: ['USDT', 'AVAX'],
    addLiquidityUrl:
      'https://avadex.complus.exchange/#/add/AVAX/0xde3A24028580884448a5397872046a019649b084',
    buyTokenUrl:
      'https://avadex.complus.exchange/#/swap?inputCurrency=0xde3A24028580884448a5397872046a019649b084&outputCurrency=AVAX',
  },
  {
    id: 'com-eth-avax-eol',
    logo: 'avax-pairs/ETH-AVAX.png',
    name: 'ETH-AVAX LP',
    token: 'ETH-AVAX CLP',
    tokenDescription: 'Complus',
    tokenAddress: '0x0CA373D27CE17C4804D108afEee0A77EfEb33775',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComplusETH-AVAX',
    earnedTokenAddress: '0x0c89Ca08b6831e6b81f9f969F37A966a2C44d3d1',
    earnContractAddress: '0x0c89Ca08b6831e6b81f9f969F37A966a2C44d3d1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'com-eth-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Complus',
    assets: ['ETH', 'AVAX'],
    addLiquidityUrl:
      'https://avadex.complus.exchange/#/add/AVAX/0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15',
    buyTokenUrl:
      'https://avadex.complus.exchange/#/swap?inputCurrency=0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15&outputCurrency=AVAX',
  },
  {
    id: 'com-png-avax-eol',
    logo: 'avax-pairs/PNG-AVAX.png',
    name: 'PNG-AVAX LP',
    token: 'PNG-AVAX CLP',
    tokenDescription: 'Complus',
    tokenAddress: '0xEcee953e187d9e82D57bAedAaEF1e56E5283f5C3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComplusPNG-AVAX',
    earnedTokenAddress: '0x7076a33b6525132fF77F0FeE2daB2a1e79688DA0',
    earnContractAddress: '0x7076a33b6525132fF77F0FeE2daB2a1e79688DA0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'com-png-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Complus',
    assets: ['PNG', 'AVAX'],
    addLiquidityUrl:
      'https://avadex.complus.exchange/#/add/AVAX/0x60781C2586D68229fde47564546784ab3fACA982',
    buyTokenUrl:
      'https://avadex.complus.exchange/#/swap?inputCurrency=0x60781C2586D68229fde47564546784ab3fACA982&outputCurrency=AVAX',
  },

  {
    id: 'snob-snob-avax-eol',
    logo: 'avax-pairs/SNOB-AVAX.svg',
    name: 'SNOB-AVAX LP',
    token: 'SNOB-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xa1C2c3B6b120cBd4Cec7D2371FFd4a931A134A32',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSnowballSNOB-AVAX',
    earnedTokenAddress: '0x114c5f7f42fB75b7960aa3e4c327f53288360F58',
    earnContractAddress: '0x114c5f7f42fB75b7960aa3e4c327f53288360F58',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'snob-snob-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Snowball',
    assets: ['SNOB', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xC38f41A296A4493Ff429F1238e030924A1542e50',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xC38f41A296A4493Ff429F1238e030924A1542e50',
  },

  {
    id: 'com-com-avax',
    logo: 'avax-pairs/COM-AVAX.png',
    name: 'COM-AVAX LP',
    token: 'COM-AVAX LP',
    tokenDescription: 'Complus',
    tokenAddress: '0xF0ED25fD26E0b64C86c6c78b661F2ef283E9B6FF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComplusCOM-AVAX',
    earnedTokenAddress: '0xA43d8f6Db69610C8260B953658553cabF01D77c6',
    earnContractAddress: '0xA43d8f6Db69610C8260B953658553cabF01D77c6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'com-com-avax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Complus',
    assets: ['COM', 'AVAX'],
    addLiquidityUrl:
      'https://avadex.complus.exchange/#/add/AVAX/0x3711c397B6c8F7173391361e27e67d72F252cAad',
    buyTokenUrl:
      'https://avadex.complus.exchange/#/swap?inputCurrency=0x3711c397B6c8F7173391361e27e67d72F252cAad&outputCurrency=AVAX',
  },

  {
    id: 'com-dai-avax-eol',
    logo: 'avax-pairs/DAI-AVAX.png',
    name: 'DAI-AVAX LP',
    token: 'DAI-AVAX LP',
    tokenDescription: 'Complus',
    tokenAddress: '0x7583a59a50d761E491d0c9393cA5214dbB613806',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComplusDAI-AVAX',
    earnedTokenAddress: '0xB5F0fF997BEc850b11792ed07b2B5AbDEa869B84',
    earnContractAddress: '0xB5F0fF997BEc850b11792ed07b2B5AbDEa869B84',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'com-dai-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Complus',
    assets: ['DAI', 'AVAX'],
    addLiquidityUrl:
      'https://avadex.complus.exchange/#/add/AVAX/0xbA7dEebBFC5fA1100Fb055a87773e1E99Cd3507a',
    buyTokenUrl:
      'https://avadex.complus.exchange/#/swap?inputCurrency=0xbA7dEebBFC5fA1100Fb055a87773e1E99Cd3507a&outputCurrency=AVAX',
  },

  {
    id: 'png-sushi-avax-eol',
    name: 'SUSHI-AVAX LP',
    token: 'SUSHI-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xd8B262C0676E13100B33590F10564b46eeF652AD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinSUSHI-AVAX',
    earnedTokenAddress: '0x3094Ab4Af54f5208B867125B5CCeCc94Bc17cbB6',
    earnContractAddress: '0x3094Ab4Af54f5208B867125B5CCeCc94Bc17cbB6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-sushi-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['SUSHI', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x39cf1BD5f15fb22eC3D9Ff86b0727aFc203427cc',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x39cf1BD5f15fb22eC3D9Ff86b0727aFc203427cc',
  },

  {
    id: 'png-wbtc-avax-eol',
    name: 'WBTC-AVAX LP',
    token: 'WBTC-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x7a6131110B82dAcBb5872C7D352BfE071eA6A17C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinWBTC-AVAX',
    earnedTokenAddress: '0xd5ab3Fac6200B0D8e8d76daED62793026118A78c',
    earnContractAddress: '0xd5ab3Fac6200B0D8e8d76daED62793026118A78c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-wbtc-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['WBTC', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x408D4cD0ADb7ceBd1F1A1C33A0Ba2098E1295bAB',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x408D4cD0ADb7ceBd1F1A1C33A0Ba2098E1295bAB',
  },

  {
    id: 'png-link-avax-eol',
    name: 'LINK-AVAX LP',
    token: 'LINK-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xbbC7fFF833D27264AaC8806389E02F717A5506c9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinLINK-AVAX',
    earnedTokenAddress: '0x6571052b2FB67DF6DD003ED6ed371098A030Eb0d',
    earnContractAddress: '0x6571052b2FB67DF6DD003ED6ed371098A030Eb0d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-link-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['LINK', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xB3fe5374F67D7a22886A0eE082b2E2f9d2651651',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xB3fe5374F67D7a22886A0eE082b2E2f9d2651651',
  },

  {
    id: 'png-uni-avax-eol',
    name: 'UNI-AVAX LP',
    token: 'UNI-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x92dC558cB9f8d0473391283EaD77b79b416877cA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUNI-AVAX',
    earnedTokenAddress: '0x17657955D954bD7F7315C388D7099af7B0b851FA',
    earnContractAddress: '0x17657955D954bD7F7315C388D7099af7B0b851FA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-uni-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['UNI', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xf39f9671906d8630812f9d9863bBEf5D523c84Ab',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xf39f9671906d8630812f9d9863bBEf5D523c84Ab',
  },

  {
    id: 'png-usdt-png-eol',
    name: 'USDT-PNG LP',
    token: 'USDT-PNG LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xE8AcF438B10A2C09f80aEf3Ef2858F8E758C98F9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUSDT-PNG',
    earnedTokenAddress: '0x044e87f30bd9bD961c04028aC69155493E1b9eD0',
    earnContractAddress: '0x044e87f30bd9bD961c04028aC69155493E1b9eD0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-usdt-png',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['USDT', 'PNG'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x60781C2586D68229fde47564546784ab3fACA982/0xde3A24028580884448a5397872046a019649b084',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xde3A24028580884448a5397872046a019649b084',
  },

  {
    id: 'png-eth-avax-eol',
    name: 'ETH-AVAX LP',
    token: 'ETH-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x1aCf1583bEBdCA21C8025E172D8E8f2817343d65',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinETH-AVAX',
    earnedTokenAddress: '0xDA875A511860f2752B891677489d08CaEDac00EA',
    earnContractAddress: '0xDA875A511860f2752B891677489d08CaEDac00EA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-eth-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['ETH', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15',
  },

  {
    id: 'png-usdt-avax-eol',
    name: 'USDT-AVAX LP',
    token: 'USDT-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x9EE0a4E21bd333a6bb2ab298194320b8DaA26516',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUSDT-AVAX',
    earnedTokenAddress: '0x7a670e849DB824364d1031DEAfB4cD603144F23D',
    earnContractAddress: '0x7a670e849DB824364d1031DEAfB4cD603144F23D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-usdt-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['USDT', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xde3A24028580884448a5397872046a019649b084',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xde3A24028580884448a5397872046a019649b084',
  },

  {
    id: 'png-png-avax',
    name: 'PNG-AVAX LP',
    token: 'PNG-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xd7538cABBf8605BdE1f4901B47B8D42c61DE0367',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinPNG-AVAX',
    earnedTokenAddress: '0x71b5852857b85D5096d4288AD6d293F217d8e162',
    earnContractAddress: '0x71b5852857b85D5096d4288AD6d293F217d8e162',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-png-avax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['PNG', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x60781C2586D68229fde47564546784ab3fACA982',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x60781C2586D68229fde47564546784ab3fACA982',
  },
];
